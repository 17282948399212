/*** IMPORTS FROM imports-loader ***/
(function() {
var define = false;

'use strict';

require('./styles/styles.less');

function getAsset(name) {
    require('./img/' + name);
}

// requestAnimationFrame(function () {
//     requestAnimationFrame(function () {
//         requestAnimationFrame(function () {
//             requestAnimationFrame(function () {
//                 $('body').css('opacity', '1');
//             });
//         });
//     });
// });

////////

window.qs = function(n) {
    n = n.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&");
    var t = location.search.match(new RegExp("[?&]" + n + "=([^&]+)(&|$)"));
    return t && decodeURIComponent(t[1].replace(/\+/g, " "))
};

$(function() {
    var n = window.location.pathname.split("/")[1], t = "/" + n;
    $(".primary-nav li").each(function () {
        var n = $(this).find("a").attr("href");
        n === t && $(this).find("a").addClass("selected")
    });

    $(".lightbox").colorbox({
        rel: "lightbox",
        transition: "fade",
        slideshow: !0,
        width: "75%",
        height: "75%"
    });

    $("input").click(function () {
        $(this).keypress()
    });
    $("textarea").click(function () {
        $(this).keypress()
    });


    ///////////////////////////////////////////////////////////////////////////////////////////////////////


    window.VD = {};
    (function() {
        function n() {
            var n = VD.getIEVersion();
            n != -1 && n.major >= 6 && n.major <= 8 && $("img").filter(function() {
                return typeof $(this).attr("data-interchange") != "undefined"
            }).each(a);
            l();
            $(".home #main").each(function () {
                nt(!0)
            });
            $(".our-services").each(function() {
                f()
            });
            $(".section-menu").each(c);
            $("#seo-process li").each(k);
            $(".technology-banner .inner").scaleContent({
                breakpoint: 767,
                maxHeight: 535
            });
            $(".technology-intro-block").scaleContent({
                breakpoint: 979,
                maxHeight: 569,
                ratio: 2.5
            });
            $(".banner.service-area").scaleFSImage({
                ratio: 3.2105,
                breakpoint: 979,
                minheight: 450,
                maxwidth: 2e3
            });
            $(".banner.casestudy-banner").scaleFSImage({
                ratio: 2.258,
                breakpoint: 979,
                minheight: 760,
                maxwidth: 2e3
            });
            $(".diamond-set .diamond").each(h);
            $(".functionality-overview .area").each(y);
            $(".diagram").each(s);
            $("#casestudy-previews").each(t);
            $("#blog-feed").each(r);
            $(".block-link").each(d);
            $(".profile-diamonds").each(p);
            $(".footer-enquiries .input-form").each(w);
            $("#vacancies-list").each(b);
            n != -1 && n.major >= 6 && n.major <= 8 && o()
        }
        var t = function() {
            function n(n, t) {
                var r = $(".overlay", n);
                t == "out" ? (clearTimeout(i),
                    r.velocity({
                        opacity: 0,
                        top: "-30%"
                    }, 300, "easeInOutQuad"),
                    n.addClass("active"),
                    i = setTimeout(function() {
                        o(n, t)
                    }, 150)) : (clearTimeout(u),
                    n.removeClass("active"),
                    $(".info", n).removeClass("overlaid"),
                    f($(".title", n), t, $("img", n)),
                    u = setTimeout(function() {
                        r.velocity({
                            opacity: 1,
                            top: 0
                        }, {
                            complete: function() {
                                r.closest("li").removeClass("triggered")
                            }
                        }, 300, "easeInOutQuad")
                    }, 100))
            }
            function o(n, t) {
                if (n.hasClass("active")) {
                    var r = $(".info", n);
                    r.addClass("overlaid");
                    i = setTimeout(function() {
                        f($(".title", r), t, $("img", n))
                    }, 200)
                }
            }
            function f(n, t, i) {
                var r, u;
                t == "out" ? (n.addClass("active"),
                    r = s(i, n, !0),
                    n.css("paddingTop", parseFloat(r) + 5 + "%").velocity({
                        opacity: 1,
                        paddingTop: r + "%"
                    }, 250, "easeInOutQuad")) : (u = Math.floor(parseFloat(n.css("paddingTop"))) + 15,
                    n.velocity({
                        opacity: 0,
                        top: u
                    }, {
                        complete: function() {
                            n.removeAttr("style").removeClass("active")
                        }
                    }, 200, "easeOutQuad"))
            }
            function s(n, t, i) {
                var r, u, f;
                if (n.length && t.length)
                    u = n.height(),
                        f = t.height(),
                        r = Math.round(u / 2 - f / 2),
                        r = i ? (r / n.height() * 100).toFixed(2) : r;
                else
                    return;
                return r
            }
            var t = $("> ul", $(this)), r = t.children(), e = VD.isTouch(), i, u;

            $(".info", r).each(function(aa) {
                $(this).after($("<a>").addClass("overlay").attr('target', '_blank').attr('href', $(".button", $(this)).attr("href")));
            });

            t.on({
                mouseenter: function() {
                    n($(this), "out")
                },
                mouseleave: function() {
                    n($(this), "in")
                },
                click: function() {
                    e && ($(".info", $(this)).hasClass("overlaid") ? n($(this), "in") : $(this).hasClass("triggered") || (n($(this), "out"),
                        $(this).addClass("triggered")))
                }
            }, "> li");
            t.on({
                mouseleave: function() {
                    n($("> li", $(this)), "in")
                }
            });
            // $(".overlay", r).on("click", function() {
            //     //window.location.href = $(".button", $(this).parent()).attr("href");
            //     window.open($(".button", $(this).parent()).attr("href"), '_blank');
            // })
        }
            , i = function() {
            function s() {
                VD.getViewport().width >= 768 && !f ? ($("#footer-placeholder > .container", a).append($("#footer")),
                    $("#hp-screens").fullpage({
                        sectionSelector: ".section-wipe",
                        onLeave: function(u, f) {
                            var e = $(this);
                            f == 3 || f == 5 || f == 6 ? (o.addClass(r),
                                i.css("opacity", 0),
                                i.addClass(r),
                                i.velocity({
                                    opacity: 1
                                }, 300, "easeOutQuad")) : i.hasClass(r) && (o.removeClass(r),
                                i.css("opacity", 0),
                                i.removeClass(r),
                                i.velocity({
                                    opacity: 1
                                }, 300, "easeOutQuad"));
                            f == 6 ? $("#header").addClass("transbg") : f == 1 ? t && n.play() : f == 2 && t && n.pause();
                            u == 6 && $("#header").removeClass("transbg")
                        }
                    }),
                    h(),
                    f = !0) : VD.getViewport().width < 768 && f && ($.fn.fullpage.destroy("all"),
                    $("#footer").insertAfter($(".outer-wrap")),
                    f = !1,
                t && (n.pause(),
                    u.removeClass("videoloaded"),
                    $(n).hide()));
                t && u.hasClass("videoloaded") && c()
            }
            function h() {

            }
            function c() {
                var i = $(n).height()
                    , r = $(n).width()
                    , t = VD.getViewport();
                i > t.height ? $(n).css({
                    top: -Math.round((i - t.height) / 2)
                }) : $(n).css({
                    top: 0
                });
                r > t.width ? $(n).css({
                    left: -Math.round((r - t.width) / 2)
                }) : $(n).css({
                    left: 0
                })
            }
            function l(n, t) {
                return n.canPlayType(t)
            }
            var a = $(this), n, t = !1, e = $("#video-banner .video-bg"), u = $("#video-banner"), r = "negative", o = $("#header .primary-nav a, #header .primary-nav li"), i = $("#header .logo"), f = !1;
            h();
            s();
            $(window).smartresize(s)
        }
            , r = function() {
            function u() {
                var t = $(".latest-posts").closest(".columns");
                $(".latest-posts").css("width", 100 + 1300 / t.width() + "%");
                n.addClass("js-masonry");
                n.masonry({
                    columnWidth: ".grid-sizer",
                    itemSelector: ".story"
                })
            }
            var i = 450, r = ["orange", "white", "green", "grey"], n = $(".latest-posts", $(this)), t;
            $(".story > div", $(this)).append($("<div>").addClass("triangle"));
            qs("showall") === "true" ? ($("#postList li.story").show()) : ($("#show-more-blogs-button").attr("data-last-visible-item", "9"),

                $("#postList li.story").slice(0, 9).show());

            t = 0;
            n.children("li:gt(0)").each(function() {
                t >= r.length && (t = 0);
                $(this).addClass(r[t]);
                t++
            });
            VD.getViewport().width > i && u();
            $(window).resize(function() {
                VD.getViewport().width <= i ? n.hasClass("js-masonry") && (n.masonry("destroy"),
                    n.removeClass("js-masonry"),
                    $(".latest-posts").css("width", "100%")) : n.hasClass("js-masonry") || u()
            })
        }
            , g = function() {
            $(".story").each(function() {
                var n = $(".tag-categories li", $(this)), t;
                $(".tag-categories li:gt(2)", $(this)).hide();
                n.length > 3 && (t = n.eq(2).text(),
                    n.eq(2).text(t.slice(0, -1)))
            })
        }
            , u;
        VD.endBlogFeed = function() {
            $("#postList + h4").fadeOut(800)
        }
        ;
        u = function() {
            $("#postList li.story").slice(10, 20).show()
        }
        ;
        VD.refreshBlogFeed = function() {
            var i = $("#show-more-blogs-button")
                , n = parseInt(i.attr("data-last-visible-item"))
                , t = n + 10;

            t >= $("#postList li.story").length && (t = $("#postList li.story").length);

            $("#postList li.story").slice(n, t).show();

            i.attr("data-last-visible-item", t.toString());
            $("#postList").masonry("layout")
        }
        ;
        VD.showAllBlogs = function() {
            $("#postList li.story").each(function() {
                $(this).show()
            });
            $("#show-more-blogs-button").parent().parent().hide();
            $("#postList").masonry("layout")
        }
        ;
        var f = function() {
                function nt() {
                    var n;
                    for (window.addEventListener("scroll", v, !1),
                             window.addEventListener("mousewheel", h, !1),
                             window.addEventListener("DOMMouseScroll", h, !1),
                             f = !0,
                             n = 0; n < a.length; n++)
                        s(a[n]);
                    for (n = 0; n < t.length; n++)
                        s(t[n]);
                    y()
                }
                function s(n) {
                    n.style.visibility = "hidden";
                    r(n, 0)
                }
                function h(n) {
                    c = !0;
                    n.preventDefault && n.preventDefault();
                    n.wheelDelta ? o = n.wheelDelta / 120 : n.detail && (o = -n.detail / 3);
                }
                function v() {
                    f = !0
                }
                function i(n) {
                    return document.documentElement.scrollTop == 0 ? document.body.scrollTop + n : document.documentElement.scrollTop + n
                }
                function r(n, t) {
                    var i = "translate3d(0px, " + t + "px, 0)";
                    n.style[d] = i
                }
                function y() {
                    var h, v, rt, d, a, tt;
                    if (f && u) {
                        for (h = 0; h < n.length; h++)
                            if (v = w($(n[h]).attr("data-windowid")), p(n[h])) {
                                rt = $(n[h]).hasClass("top-item");
                                d = $(n[h]).offset().top;
                                v.style.height = $(n[h]).height() + "px";
                                r(v, Math.round(d - i(0)));
                                v.style.visibility = "visible";
                                var ft = v.querySelector(".scroll-content"), ut = v.querySelector(".scroll-image"), g = $(v).height(), et = $(ut).height(), nt = g - et, b;
                                b = rt ? -Math.round((i($("#header").innerHeight()) - it(n[h])) / g * nt) : -Math.round((i(0) - d) / g * nt);
                                b = Math.max(b, nt);
                                r(ut, b);
                                r(ft, 0)
                            } else
                                s(v);
                        for (a = 0; a < t.length; a++)
                            tt = $(t[a]).closest(".desc"),
                                p(tt) ? (t[a].style.visibility = "visible",
                                    r(t[a], -1 * i(0) / $(t[a]).attr("data-speed")),
                                $(t[a]).hasClass("animation") && e(i(0), tt)) : s(t[a]);

                        f = !1
                    }
                    c && u && (window.scrollBy(0, -o * 10), l++, l > 20 && (l = 0,c = !1,o = 0));
                    k(y)
                }
                function p(n) {
                    var t = $(n).offset().top
                        , r = i(0)
                        , u = $(n).height();
                    return t < r + $(window).height() && t + u > r ? !0 : !1
                }
                function w(n) {
                    return document.getElementById(n)
                }
                function tt() {
                    return $("#header").innerHeight()
                }
                function it(n) {
                    return $(n).hasClass("top-item") ? $(n).offset().top : $(n).offset().top + tt()
                }
                function rt(n) {
                    for (var t = 0; t < n.length; t++)
                        if (typeof document.body.style[n[t]] != "undefined")
                            return n[t];
                    return null
                }
                function ut() {
                    for (var i, t = 0; t < n.length; t++) i = $(n[t]).children(), $("#parallaxset").append(i)
                }
                function ft() {
                    for (var t = 0; t < n.length; t++) $(n[t]).append(w($(n[t]).attr("data-windowid")));
                    $(".parallax-wrap, .scroll-image, .para-gfx").removeAttr("style");
                    window.removeEventListener("scroll", v, !1);
                    window.removeEventListener("mousewheel", h, !1);
                    window.removeEventListener("DOMMouseScroll", h, !1)
                }
                function b() {
                    u = !g || VD.isTouch() || VD.getViewport().width < 980 ? !1 : !0;
                    u = u && ($('.parallax-scroll, .parallax-wrap').length);
                    u ? $("#main").hasClass("parallax") || (ut(), $("#main").addClass("parallax"), nt()) : ($("#main").removeClass("parallax"),
                    $("#parallaxset").children().length && ft())
                }
                var u = !0
                    , k = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame
                    , d = rt(["transform", "msTransform", "webkitTransform", "mozTransform", "oTransform"])
                    , g = $("html").hasClass("csstransforms3d")
                    , a = document.querySelectorAll(".parallax-wrap")
                    , n = document.querySelectorAll(".scroll-window")
                    , t = document.querySelectorAll(".para-gfx")
                    , f = !1
                    , c = !1
                    , l = 0
                    , o = 0;

                if (!VD.isTouch()) {
                    b(); $(window).resize(b);
                }
            }
            , e = function(n, t) {
                n = n + $(window).height();
                var f = $(t).offset().top
                    , r = $(t).height()
                    , i = Math.round((n - (f + r / 2)) / r * 100)
                    , u = VD.getViewport().width > 1229 ? "large" : "small";
                $(t).find(".demographics") && i > 0 && i <= 100 && $(".demographics").children().each(function() {
                    var n = parseInt($(this).attr("data-increment-" + u))
                        , t = i / 100
                        , r = parseInt($(this).attr("data-maxwidth-" + u))
                        , f = Math.floor(r * t / n);
                    $(this).width(n * f)
                });
                $(t).find(".usersegments")
            }
            , nt = function(n) {
                function g() {
                    window.addEventListener("scroll", nt, !1);
                    window.addEventListener("mousewheel", l, !1);
                    window.addEventListener("DOMMouseScroll", l, !1);
                    r = !0;
                    a()
                }
                function l(n) {
                    o = !0;
                    n.preventDefault && n.preventDefault();
                    n.wheelDelta ? u = n.wheelDelta / 120 : n.detail && (u = -n.detail / 3);
                }
                function nt() {
                    r = !0
                }
                function h(n) {
                    return document.documentElement.scrollTop == 0 ? document.body.scrollTop + n : document.documentElement.scrollTop + n
                }
                function c(n, t) {
                    var i = "translate3d(0px, " + t + "px, 0)";
                    n.style[k] = i
                }
                function a() {
                    var n, l;
                    if (r && i) {
                        for (n = 0; n < t.length; n++) {
                            var y = t[n]
                                , p = t[n].querySelector(".scroll-image")
                                , w = $(y).innerHeight()
                                , d = $(p).innerHeight()
                                , k = w - d;
                            scrollVal = -Math.round((h(f()) - v(y)) / w * k);
                            scrollVal = Math.max(scrollVal, k);
                            c(p, scrollVal)
                        }
                        for (l = 0; l < e.length; l++) {
                            c(e[l], -1 * h(f()) / $(e[l]).attr("data-speed"));
                        }
                        r = !1;
                    }
                    o && i && (window.scrollBy(0, -u * 10),
                        s++,
                    s > 20 && (s = 0,
                        o = !1,
                        u = 0));
                    b(a)
                }
                function f() {
                    return $("#header").innerHeight()
                }
                function v(n) {
                    return $(n).hasClass("top-item") ? $(n).offset().top : $(n).offset().top + f()
                }
                function y(n) {
                    for (var r, u, e, s, l, o, i = 0; i < t.length; i++)
                        r = t[i],
                            u = t[i].querySelector(".scroll-image"),
                            n && w ? (e = $(r).innerHeight(),
                                s = Math.round(e * tt(r)),
                                u.style.height = s + "px",
                                l = e - s,
                                o = -Math.round((h(f()) - v(r)) / e * l),
                                o = Math.max(o, l),
                                c(u, o)) : $(u).removeAttr("style")
                }
                function tt(n) {
                    return parseFloat($(n).attr("data-ratio"))
                }
                function it(n) {
                    for (var t = 0; t < n.length; t++)
                        if (typeof document.body.style[n[t]] != "undefined")
                            return n[t];
                    return null
                }
                function p() {
                    i = !d || VD.isTouch() || VD.getViewport().width < 980 ? !1 : !0;
                    i ? ($("#main").hasClass("parallax") || (g(),
                        $("#main").addClass("parallax")),
                        y(!0)) : ($("#main").removeClass("parallax"),
                        y(!1))
                }
                var i = !0
                    , w = n
                    , b = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame
                    , k = it(["transform", "msTransform", "webkitTransform", "mozTransform", "oTransform"])
                    , d = $("html").hasClass("csstransforms3d")
                    , t = document.querySelectorAll(".parallax-wrapper")
                    , e = document.querySelectorAll(".para-gfx")
                    , r = !1
                    , o = !1
                    , s = 0
                    , u = 0;

                if (!VD.isTouch()) {
                    p();
                    $(window).resize(p);
                }
            }
            , o = function() {
                function i(n, t, i) {
                    return n.substr(0, t) + i + n.substr(t)
                }
                function n(n) {
                    return url = /url\(\s*(['"]?)(.*?)\1\s*\)/g.exec(n)[2]
                }
                var t = "-polyfill";
                $(".old-ie .circle-feature-set li").each(function() {
                    var e = $(".porthole", $(this)).css("backgroundImage"), o = $(this).find("a").attr("href"), r = n(e), u, f;
                    $(".porthole", $(this)).remove();
                    u = $("<a>").attr("href", o);
                    f = $("<img>").attr("src", i(r, r.length - 4, t));
                    $(".porthole-wrapper", $(this)).append(u.append(f))
                });
                $(".old-ie .diamond-set li").each(function() {
                    var f = $(".diamond", $(this)).css("backgroundImage"), r = n(f), u;
                    $(".inner", $(this)).remove();
                    u = $("<img>").attr("src", i(r, r.length - 4, t));
                    $(".diamond", $(this)).append(u).css({
                        backgroundImage: "none",
                        paddingBottom: "0"
                    })
                });
                $(".old-ie .scroll-image.scaled").each(function() {
                    var t = $(this).css("backgroundImage")
                        , i = n(t);
                    $(this).css({
                        background: "none",
                        height: "auto"
                    }).append($("<img>").attr("src", i))
                })
            }
            , s = function() {
                function r() {
                    var r = VD.getViewport().width, f;
                    r <= u ? (f = (n.width() - i.width()) / 2 - n.width() * .04,
                        t.each(function() {
                            var i = $(this).hasClass("rh")
                                , n = f
                                , t = $(this).attr("data-inset");
                            typeof t != "undefined" && (n += parseInt(t),
                            r < 980 && (n -= parseInt(t) / 2));
                            $(this).css("width", n + "px");
                            i ? $(this).css("right", "25px") : $(this).css("left", "25px")
                        })) : t.each(function() {
                        $(this).removeAttr("style")
                    })
                }
                var n = $(this)
                    , i = $(".gfx", n)
                    , u = 1430
                    , t = $(".guide-info", n);
                captionMaxWidths = [];
                t.each(function() {
                    captionMaxWidths.push({
                        sWidth: $(this).width(),
                        sPos: parseInt($(this).css("left"))
                    })
                });
                $(".guide-info .inner", n).before($("<span>").addClass("circle-icon")).after($("<span>").addClass("dot"));
                i.load(r);
                $(window).resize(r)
            }
            , h = function() {
                function s() {
                    n.closest("ul").children().each(function() {
                        var n = $(this);
                        $(".inner", n).hasClass("over") && u($(".inner", n), $(".slider", n), $(".message", n))
                    });
                    n.addClass("over");
                    t.css("left", 0);

                    setTimeout(function () {
                        r.addClass("visible");
                    }, 250)
                }
                function u(n, t, i) {
                    n.removeClass("over");
                    t.css("left", -t.width());
                    i.removeClass("visible")
                }
                function h() {
                    var r = n.width(), i = n.height();
                    t.addClass("disabled").height(i);
                    t.height(i);
                    n.hasClass("over") ? t.css("left", 0) : t.css("left", -t.width());
                    t.removeClass("disabled")
                }
                var n = $(".inner", $(this))
                    , i = $(".overlay", n)
                    , c = $(".symbol", n)
                    , t = $(".slider", n)
                    , r = $(".message", n)
                    , f = VD.isTouch()
                    , e = VD.isSmallScreen()
                    , o = VD.notOldIE();

                if (f || e || !o) {
                    if (f && !e)
                        i.on("click", function(i) {
                            n.hasClass("over") ? u(n, t, r) : s();
                            i.preventDefault()
                        })
                } else {
                    i.on("mouseenter", s);
                    i.on("mouseleave", function() {
                        u(n, t, r)
                    })
                }
                o && (h(), $(window).resize(h))
            }
            , c = function() {
                function a() {
                    r.addClass(e);
                    f = setInterval(function() {
                        t.removeClass("hidden");
                        i()
                    }, 300)
                }
                function v() {
                    u || (o ? ($(".inner", $(this)).removeClass("open"),
                        n.addClass("hidden"),
                        t.css({
                            top: "0"
                        }),
                        u = !0,
                        i(),
                        f = setInterval(function() {
                            n.removeClass("hidden sticky");
                            $("body").css("paddingTop", "0");
                            u = o = !1;
                            i()
                        }, 300)) : ($(".inner", $(this)).addClass("open"),
                        n.addClass("sticky hidden"),
                        $("body").css("paddingTop", n.outerHeight()),
                        u = !0,
                        i(),
                        f = setInterval(function() {
                            n.removeClass("hidden");
                            t.css({
                                top: n.outerHeight()
                            });
                            o = !0;
                            u = !1;
                            i()
                        }, 300)))
                }
                function i() {
                    clearInterval(f)
                }
                function y() {
                    r.removeClass(e);
                    i();
                    t.addClass("hidden")
                }
                function p(n) {
                    return n.offset().top + n.outerHeight()
                }
                function w() {
                    return $(window).scrollTop() + ($("#header").height() - 20)
                }
                function c() {
                    p(h) <= w() ? r.hasClass(e) || a() : r.hasClass(e) && y()
                }
                function b(n) {
                    var i = $("<div>").addClass("row")
                        , t = $("<nav>").addClass("sub-nav sticky hidden");
                    return t.append(i),
                        n.clone().appendTo(i),
                        $("ul", t).removeClass(),
                        t.append(l()),
                        t
                }
                function l() {
                    return $("<div>").addClass("back-to-top").append($("<div>").addClass("inner").append($("<div>").addClass("icon")))
                }
                var r = $(this), f, e = "sticky", u = !1, s = $("div.services > div#main").hasClass("our-services"), o = !1, h = s ? $(this) : $("#header"), n, t;
                s ? ($("li a", $(this)).prepend($("<span>").addClass("hover-icon")).prepend($("<span>").addClass("icon")),
                    $("body").append(b($("ul", h)))) : r.append(l());
                n = $("#header");
                t = $(".sub-nav");
                $(".back-to-top", t).on("click", v);
                VD.isSmallScreen() || (c(), $(window).scroll(c))
            }
            , tt = function() {
                function a() {
                    r.addClass(e);
                    f = setInterval(function() {
                        t.removeClass("hidden");
                        i()
                    }, 300)
                }
                function v() {
                    u || (o ? ($(".inner", $(this)).removeClass("open"),
                        n.addClass("hidden"),
                        t.css({
                            top: "0"
                        }),
                        u = !0,
                        i(),
                        f = setInterval(function() {
                            n.removeClass("hidden sticky");
                            $("body").css("paddingTop", "0");
                            u = o = !1;
                            i()
                        }, 150)) : ($(".inner", $(this)).addClass("open"),
                        n.addClass("sticky hidden"),
                        $("body").css("paddingTop", n.outerHeight()),
                        u = !0,
                        i(),
                        f = setInterval(function() {
                            n.removeClass("hidden");
                            t.css({
                                top: n.outerHeight()
                            });
                            o = !0;
                            u = !1;
                            i()
                        }, 150)))
                }
                function i() {
                    clearInterval(f)
                }
                function y() {
                    r.removeClass(e);
                    i();
                    t.addClass("hidden")
                }
                function p(n) {
                    return n.offset().top + n.outerHeight()
                }
                function w() {
                    return $(window).scrollTop() + ($("#header").height() - 20)
                }
                function c() {
                    p(h) <= w() ? r.hasClass(e) || a() : r.hasClass(e) && y()
                }
                function b(n) {
                    var i = $("<div>").addClass("row")
                        , t = $("<nav>").addClass("sub-nav sticky hidden");
                    return t.append(i),
                        n.clone().appendTo(i),
                        $("ul", t).removeClass(),
                        t.append(l()),
                        t
                }
                function l() {
                    return $("<div>").addClass("back-to-top").append($("<div>").addClass("inner").append($("<div>").addClass("icon")))
                }
                var r = $(this), f, e = "sticky", u = !1, s = $("body").hasClass("casestudy"), o = !1, h = s ? $(this) : $("#header"), n, t;
                s ? ($("li a", $(this)).prepend($("<span>").addClass("hover-icon")).prepend($("<span>").addClass("icon")),
                    $("body").append(b($("ul", h)))) : r.append(l());
                n = $("#header");
                t = $(".sub-nav");
                $(".back-to-top", t).on("click", v);
                VD.isSmallScreen() || (c(), $(window).scroll(c))
            }
            , l = function() {
                function s(e) {
                    clearInterval(u);
                    clearInterval(t);
                    u = setInterval(function() {
                        var o = c();
                        e == "open" ? (n.css({
                            width: o
                        }, {
                            left: -(VD.getViewport().width - 80) + "px"
                        }),
                            n.css("left", 0),
                            n.addClass("open"),
                            i.css("left", o),
                            i.add(f).addClass("mob-nav-open"),
                            t = setInterval(function() {
                                r = !0;
                                clearInterval(t)
                            }, 400)) : (n.css("left", -(VD.getViewport().width - 80) + "px"),
                            n.removeClass("open"),
                            i.css("left", 0),
                            t = setInterval(function() {
                                f.removeClass("mob-nav-open");
                                r = !1;
                                clearInterval(t)
                            }, 400));
                        clearInterval(u)
                    }, 300)
                }
                function c() {
                    return VD.getViewport().width - 80 + "px"
                }
                var u, t, n = $("#mobile-nav"), e = $(".nav-open"), i = $(".inner-wrap"), f = $(".outer-wrap"), r = !1, o = $("#mobile-nav").width(), h = function() {
                        $(this).hasClass("open") ? ($(this).removeClass("open"),
                            s("close")) : ($(this).addClass("open"),
                            s("open"));
                    }
                    ;
                if (!VD.isTouch() || VD.getViewport().width >= 767) $(window).resize(function() {
                    if (r && VD.getViewport().width < 767) {
                        var t = VD.getViewport().width - 80 + "px";
                        n.css({
                            width: t
                        }, {
                            left: -t
                        });
                        i.css("left", t);
                    } else
                        VD.getViewport().width >= 767 && r && (
                            n.add(e).removeClass("open"),
                            i.css("left", 0),
                            f.removeClass("mob-nav-open"),
                            n.css({
                                width: o + "px",
                                left: -o + "px"
                            }), r = !1
                        );

                    setTimeout(function () {
                        var s = $("html, body").scrollTop();
                        $("html, body").scrollTop(s + 20);
                        $("html, body").scrollTop(s);
                    }, 100);

                });
                e.on("click", h)
            }
            , a = function() {
                var n = $(this), t;
                n.addClass("fallback-img");
                t = n.attr("data-fallback");
                typeof t != "undefined" && t.length ? n.attr("src", t) : n.hide()
            }
            , it = function() {
                function i(n) {
                    return n.offset().top
                }
                function r() {
                    return $(window).scrollTop() - $("#header").outerHeight() + $(window).height()
                }
                function u() {
                    return (f($("#header").outerHeight()) - n.offset().top + $("#header").outerHeight()) / n.height() * 100
                }
                function f() {
                    return document.documentElement.scrollTop == 0 ? document.body.scrollTop + $(window).height() - n.height() / 2 : document.documentElement.scrollTop + $(window).height() - n.height() / 2
                }
                var n = $(".desc-research"), t = [.09, .4, .1, .23, .11, .08];
                $(window).scroll(function() {
                    i($(".desc-research")) < r() && v("research-chart-one", u(), t)
                })
            }
            , v = function(n, t, i) {
                var o, u, s;
                if (t > 0 && t <= 100) {
                    $(".chart-one").children().remove();
                    $(".chart-one").append('<canvas id="research-chart-one" width="300" height="300"><\/canvas>');
                    var h = document.getElementById(n)
                        , r = h.getContext("2d")
                        , f = Math.floor(h.width / 2)
                        , e = Math.floor(h.height / 2)
                        , l = Math.min(f, e)
                        , c = [];
                    for (o = 0; o < i.length; o++)
                        c.push(i[o] * t);
                    u = [];
                    u.push("#E8522D");
                    u.push("#81DDE4");
                    u.push("#EBB769");
                    u.push("#E8522D");
                    u.push("#81DDE4");
                    u.push("#EBB769");
                    s = 0;
                    function a(n, t) {
                        var i = n / 100 * 360 * Math.PI / 180;
                        r.save();
                        r.beginPath();
                        r.moveTo(f, e);
                        r.arc(f, e, l, s, s + i, !1);
                        r.closePath();
                        r.fillStyle = t;
                        r.fill();
                        r.restore();
                        s += i
                    }
                    function v() {
                        for (var n = 0; n < c.length; n++)
                            a(c[n], u[n]);
                        r.beginPath();
                        r.moveTo(f, e);
                        r.fillStyle = "#FFFFFF";
                        r.arc(f, e, l * .6, 0, 2 * Math.PI, !1);
                        r.fill()
                    }
                    v()
                }
            }
            , y = function() {
                function i() {
                    return VD.getViewport().width >= 980 ? !0 : !1
                }
                function u(n) {
                    n.removeClass("open");
                    $(".highlights", n).css({
                        width: "50px",
                        height: "50px",
                        marginTop: "-25px",
                        marginLeft: "-25px"
                    });
                    n.hide().css("opacity", 1)
                }
                function f() {
                    return n.closest("li").innerWidth()
                }
                function e() {
                    return n.closest("li").innerHeight()
                }
                var n = $(this)
                    , t = $(".highlights", n)
                    , r = $(".inner", t);
                if (!VD.isSmallScreen()) {
                    n.on("mouseenter", function() {
                        if (i()) {
                            n.addClass("open");
                            var u = f() - 10
                                , o = e() + 150;
                            r.width(u - 16).height(o - 16);
                            t.show();
                            t.stop(!0, !0).animate({
                                width: u,
                                height: o,
                                marginTop: -(o / 2),
                                marginLeft: -(u / 2)
                            }, 100, "easeOutExpo")
                        }
                    });
                    t.on("mouseleave", function() {
                        i() && (n.removeClass("open"),
                            t.stop(!0, !0).animate({
                                width: 50,
                                height: 50,
                                marginTop: -25,
                                marginLeft: -25,
                                opacity: 0
                            }, 100, "easeOutExpo", function() {
                                $(this).hide().css("opacity", 1)
                            }))
                    })
                }
            }
            , p = function() {
                if (!VD.isTouch()) {
                    var n = $(this)
                        , t = $("ul", n).children()
                        , i = $(".row-marker", n)
                        , s = VD.isTouch()
                        , h = VD.isSmallScreen()
                        , f = VD.IEGtNine();
                    $(".diamond-wrap", n).append($("<div>").addClass("overlay rotated-bg"));
                    $(".inner", n).append($("<div>").addClass("shader rotated-bg"));
                    function e(n) {
                        if (!n.hasClass("rendered")) {
                            n.addClass("rendered");
                            var i = n.prevAll().length
                                , u = n.hasClass("two-col") ? t.slice(i, i + 2) : t.slice(i, i + 3)
                                , r = 0
                                , f = setInterval(function() {
                                r < u.length ? (u.eq(r).removeClass("scroll-hide"),
                                    r++) : clearInterval(f)
                            }, 600)
                        }
                    }
                    function o() {
                        var n = $(this).prevAll().length
                            , i = $(this).hasClass("two-col") ? t.slice(n, n + 2) : t.slice(n, n + 3);
                        i.addClass("scroll-hide")
                    }
                    function r(n) {
                        return n.offset().top + n.height()
                    }
                    function u(n) {
                        return $(window).scrollTop() + $(window).height() + n.height()
                    }
                    VD.isTouch() || VD.isSmallScreen() || !f || (i.filter(function() {
                        return r($(this)) >= u($(this))
                    }).each(o),
                        $(window).scroll(function() {
                            i.each(function() {
                                r($(this)) < u($(this)) && e($(this))
                            })
                        }))
                }
            }
            , w = function() {
                function t() {
                    var t = !0;
                    return n.each(function() {
                        $(this).val().length <= 0 && (t = !1)
                    }),
                        t
                }
                var n = $('.input[type="text"], textarea', $(this))
            }
            , b = function() {
                function y() {
                    var f, h, c;
                    for (s.css({
                        position: "relative",
                        overflow: "hidden",
                        left: 0,
                        top: 0,
                        "z-index": 2
                    }),
                             n.css({
                                 position: "relative",
                                 "z-index": 1,
                                 left: 0,
                                 top: 0
                             }),
                             n.children().css({
                                 overflow: "hidden",
                                 float: "left",
                                 minHeight: "200px"
                             }),
                             n.children().each(function(n) {
                                 $(this).addClass(n.toString())
                             }),
                             r = $("<ul>"),
                             f = 0; f < i; f++)
                        r.append($("<li>"));
                    r.hide();
                    l.append(r);
                    a = n.children().eq(0);
                    n.children().eq(0).clone(!0).appendTo(n);
                    n.children().eq(i - 1).clone(!0).insertBefore(a);
                    i += 2;
                    n.css("left", "-" + t.width() + "px");
                    h = document.createElement("div");
                    c = document.createElement("div");
                    e = $(c);
                    o = $(h);
                    o.addClass("widescreenscroll scrollleft");
                    e.addClass("widescreenscroll scrollright");
                    l.prepend(o).prepend(e);
                    b();
                    o.on("click", function() {
                        var n = u > 0 ? u - 1 : r.children().length - 1;
                        k(n);
                        p(0, n)
                    });
                    e.on("click", function() {
                        var n = u < r.children().length - 1 ? u + 1 : 0;
                        k(n);
                        p(1, n)
                    })
                }
                function p(i, r) {
                    v = !0;
                    var f = t.width()
                        , e = i === 1 ? parseInt(n.css("left")) - f : parseInt(n.css("left")) + f;
                    n.stop(!1, !0).animate({
                        left: e
                    }, 700, "easeInOutCubic", w);
                    u = r
                }
                function c() {
                    return t.width() * i
                }
                function b() {
                    if (f) {
                        if (t.width() !== h) {
                            var r = h - t.width();
                            n.css("left", parseInt(n.css("left").replace("px", "")) + r + "px");
                            h = t.width()
                        }
                        s.css("width", c() / i + "px");
                        n.css("width", c() + "px");
                        n.children().css("width", c() / i + "px")
                    }
                    VD.getViewport().width < 980 && f ? d() : f || (f = !0,
                        y(),
                        $(".widescreenscroll").show())
                }
                function d() {
                    f = !1;
                    n.children().eq(0).remove();
                    n.children().eq(n.children().length - 1).remove();
                    s.css({
                        position: "relative",
                        overflow: "auto",
                        left: "auto",
                        top: "auto",
                        "z-index": 2
                    });
                    n.css({
                        position: "relative",
                        "z-index": 1,
                        left: "auto",
                        top: "auto",
                        width: "100%"
                    });
                    n.children().css({
                        overflow: "auto",
                        float: "none",
                        minHeight: "0"
                    });
                    i -= 2;
                    e.add(o).remove();
                    r.remove()
                }
                function k(n) {
                    r.children().eq(u).removeClass("selected");
                    r.children().eq(n).addClass("selected")
                }
                var l = $(this), s = $(".caro-wrapper", this), t = $(".row", this), n = $(".outer-list", this), a, v = !1, u = 0, h = t.width(), e, o, i = n.children().length, f = !1, r, w;
                i > 1 && (VD.getViewport().width >= 980 && (f = !0, y()), $(window).resize(b));
                w = function() {
                    var u = parseInt(n.css("left").replace("px", ""))
                        , f = t.width() * r.children().length;
                    u >= 0 ? n.css("left", -f + "px") : u <= -(t.width() * (i - 1)) && n.css("left", u + t.width() * (i - 2) + "px");
                    v = !1
                }
            }
            , k = function() {
                if (!VD.isTouch()) {
                    var n = $(this);
                    function t(n) {
                        return n.offset().top + n.height()
                    }
                    function i() {
                        return $(window).scrollTop() + $(window).height()
                    }
                    n.filter(function() {
                        return t($(this)) >= i($(this))
                    }).addClass("scroll-hide");
                    $(window).scroll(function() {
                        n.each(function() {
                            t($(this)) < i($(this)) && n.removeClass("scroll-hide")
                        })
                    })
                }
            }
            , d = function() {
                if ($(".overlay", $(this)).length <= 0) {
                    var n = $("a", $(this)).first().attr("href")
                        , t = $("<a>").addClass("overlay").attr("href", n);
                    $(this).append(t)
                }
            }
            ;
        $.fn.aPosition = function() {
            return thisLeft = this.offset().left,
                thisTop = this.offset().top,
                thisParent = this.parent(),
                parentLeft = thisParent.offset().left,
                parentTop = thisParent.offset().top,
            {
                left: thisLeft - parentLeft,
                top: thisTop - parentTop
            }
        }
        ;
        VD.getViewport = function() {
            var n = window
                , t = "inner";
            return "innerWidth"in window || (t = "client",
                n = document.documentElement || document.body),
            {
                width: n[t + "Width"],
                height: n[t + "Height"]
            }
        }
        ;
        VD.newModalWindow = function(n, t, i) {
            VD.getViewport().width <= t && (t = VD.getViewport().width - 30);
            modal.open({
                content: n,
                restore: !0,
                width: t + "px",
                height: i + "px"
            });
            n.fadeIn(200)
        }
        ;
        VD.isTouch = function() {
            var n = navigator.userAgent;
            return n.indexOf("Android") >= 0 || n.indexOf("iPhone") >= 0 || n.indexOf("iPad") >= 0 || n.indexOf("iPod") >= 0 || n.indexOf("IEMobile") >= 0 || n.indexOf("BlackBerry") >= 0 ? !0 : !1
        }
        ;
        VD.isSmallScreen = function() {
            return VD.getViewport().width <= 767 ? !0 : !1
        }
        ;
        VD.getIEVersion = function() {
            var t = navigator.userAgent
                , n = t.match(/MSIE\s?(\d+)(?:\.(\d+))?/i);
            return n != null ? {
                major: n[1],
                minor: n[2]
            } : {
                major: "-1",
                minor: "-1"
            }
        }
        ;
        VD.notOldIE = function() {
            var n = VD.getIEVersion();
            return n.major > 8 || n.major < 0 ? !0 : !1
        }
        ;
        VD.IEGtNine = function() {
            var n = VD.getIEVersion();
            return n.major > 9 || n.major < 0 ? !0 : !1
        }
        ;
        $.fn.scaleContent = function(n) {
            var t = {
                startPoint: 1429,
                scaleRatio: 2.6473214285714288,
                breakpoint: 0
            }
                , n = $.extend(t, n);
            return this.each(function() {
                var i = $(this), f = n.breakpoint, e = n.maxheight, r, u;
                scaleRatio = typeof n.ratio != "undefined" ? n.ratio : t.scaleRatio;
                r = function() {
                    var n = VD.getViewport().width, r;
                    n < t.startPoint && u(n) ? (r = n / scaleRatio,
                        i.height(r)) : u(n) ? i.height(e) : i.height("auto")
                }
                ;
                u = function(n) {
                    return n > f ? !0 : !1
                }
                ;
                r();
                $(window).resize(r)
            })
        }
        ;
        $.fn.scaleFSImage = function(n) {
            var t = {
                breakpoint: 0,
                minheight: 0,
                maxwidth: 0,
                imageClass: ".fs-image-inner"
            }
                , n = $.extend(t, n);
            return this.each(function() {
                var i = $(this)
                    , r = n.ratio
                    , s = typeof n.breakpoint != "undefined" ? n.breakpoint : t.breakpoint
                    , u = typeof n.breakpoint != "undefined" ? n.minheight : t.minheight
                    , f = typeof n.maxwidth != "undefined" ? n.maxwidth : t.maxwidth
                    , e = typeof n.imageClass != "undefined" ? $(n.imageClass, i) : $(t.imageClass, i)
                    , o = function() {
                        var o = VD.getViewport().width, n, t;
                        o > s ? (n = o, t = Math.round(n / r), t < u && (t = u, n = Math.round(u * r)), n > f && (n = f, t = Math.round(n / r)), i.css("minHeight", t + "px"), e.css({
                                width: n + "px",
                                height: t + "px",
                                marginTop: "0px",
                                top: "0px",
                                marginLeft: -Math.round(n / 2) + "px"
                            })) : i.add(e).removeAttr("style")
                    }
                    ;
                o();
                if (!VD.isTouch()) $(window).resize(o)
            })
        }
        ;
        $(document).ready(n)
    })();
});

$(function () {
    var to = null;

    $('#email-form').on('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $.ajax({
            type: 'POST',
            url: '/contacts/send',
            data: $("#email-form").serialize(),
            dataType: 'json',
            success: function(data) {
                var msg = data && data.result && data.result.message;
                var success = data && data.result && data.result.status;

                if (success) $('#email-form').find("input[type=text], input[type=email], textarea").val('');
                $('#email-form-msg').html(msg).show();

                clearTimeout(to);
                to = setTimeout(function () { $('#email-form-msg').html(msg).hide(); }, 5000);
            },
            fail: function() {
                console.log('FAIL');
            }
        });
    });

    var to2 = null;

    $('#subscribe').on('submit', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $.ajax({
            type: 'POST',
            url: '/contacts/subscribe',
            data: $("#subscribe").serialize(),
            dataType: 'json',
            success: function(data) {
                var msg = data && data.result && data.result.message;
                var success = data && data.result && data.result.status;

                if (success) $('#subscribe').find("input[type=text], input[type=email], textarea").val('');
                $('#subscribe-msg').html(msg).show();

                clearTimeout(to2);
                to2 = setTimeout(function () { $('#subscribe-msg').html(msg).hide(); }, 5000);
            },
            fail: function() {
                console.log('FAIL');
            }
        });
    });

    var offset = $(window).height();
    var duration = 500;

    $(window).scroll(function() {
        var max = $(document).height() - $(window).height();
        var diff = max - $(this).scrollTop();
        var scroll = $(this).scrollTop();

        if (scroll >= offset || (diff < 100 && max - $(window).height() > 200)) $('#back-to-top').fadeIn(duration);
        else $('#back-to-top').fadeOut(duration);
    });

    if ($(this).scrollTop() > offset) $('#back-to-top').fadeIn(duration);
    else $('#back-to-top').fadeOut(duration);

    $('#back-to-top').click(function(event) {
        event.preventDefault();$('html, body').animate({scrollTop:0},duration);
        return false;
    });

    $('#back-to-top').hide();
    $('#back-to-top').removeClass('remove');
});

}.call(window));
/*** IMPORTS FROM imports-loader ***/
(function() {
var define = false;

'use strict';

require('./lib/modernizr-2.6.2');
require('./lib/jquery-1.8.2');
require('./lib/jquery-plugins');
require('./lib/foundation');
require('./lib/foundation-interchange');

$(document).foundation();

}.call(global));